<template>
  <div style="width: 100%; background: #fff;">
    <el-form :inline="true"  @input="change($event)" :model="recordsForm" ref="recordsForm" class="recordsForm" >
      <el-form-item label="任务Job ID" prop="scheduler_job_id">
        <el-input  v-model="recordsForm.scheduler_job_id" @keyup.enter.native="submitSearch" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="任务标题" prop="sms_theme">
        <el-input  v-model="recordsForm.sms_theme" @keyup.enter.native="submitSearch" clearable placeholder="请输入" />
      </el-form-item>
      <div id="foldBox">
        <el-form-item label="任务类型" prop="scheduler_type">
          <el-select clearable filterable v-model="recordsForm.scheduler_type" placeholder="请选择" >
            <el-option value="定时任务" label="定时任务"/>
            <el-option value="周期任务" label="周期任务"/>
          </el-select>
      </el-form-item>
      <el-form-item label="任务状态" prop="scheduler_status">
        <el-select clearable filterable v-model="recordsForm.scheduler_status" placeholder="请选择">
          <el-option value="运行中" label="运行中"/>
          <el-option value="已暂停" label="已暂停"/>
          <el-option value="已删除" label="已删除"/>
        </el-select>
      </el-form-item>
      </div>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" v-preventReClick @click="submitSearch" >查询</el-button>
        <el-button icon="el-icon-refresh" v-preventReClick @click="resetForm('recordsForm')">重置</el-button>
        <a  class="advanced" @click="toggleAdvanced">
          {{ advanced ? '展开' : '收起' }}
          <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down' "></i>
        </a>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-table 
        :data="records" 
        border
        row-key="id"   
        :header-cell-style="headClass" 
        id="table"  
        v-loading="pictLoading" 
        element-loading-background="rgb(255,255,255))"
        element-loading-text="数据加载中"
        element-loading-spinner="el-icon-loading">
      <el-table-column prop="scheduler_job_id" label="定时任务Job ID" fixed align="center" width="150px"></el-table-column>
      <el-table-column prop="scheduler_type" label="定时任务类型" fixed align="center"></el-table-column>
      <el-table-column prop="sms_theme" label="任务标题"  align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sms_send_time" label="创建时间"  align="center" min-width="110px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sms_send_status" label="发送状态"  align="center">
        <template slot-scope="scope">
          <el-tag  :type="scope.row.sms_send_status === '发送完成' ? 'success' :(scope.row.sms_send_status === '发送异常' ? 'danger' : '' )">
          {{scope.row.sms_send_status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="scheduler_status"  label="定时任务状态"  align="center" >
        <template slot-scope="scope">
          <el-tag :type="scope.row.scheduler_status === '运行中' ? 'primary' :(scope.row.scheduler_status === '已删除' ? 'info' : 'warning' )" disable-transitions>
          {{scope.row.scheduler_status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sql_text" label="任务关联Sql语句"  min-width="110px" align="center" show-overflow-tooltip>
        <el-link slot-scope="scope" type="info" :underline="false" @click="handleCopyLink(scope.row)">{{scope.row.sql_text}}</el-link> 
      </el-table-column>
      <el-table-column prop="next_run_time" label="下次执行时间"  min-width="135px" align="center" show-overflow-tooltip>
         <template slot-scope="scope">
           {{scope.row.next_run_time?scope.row.next_run_time:'--'}}
        </template>
      </el-table-column>
       <el-table-column label="任务概览" align="center" >
				<template scope="scope">
					<el-button  @click="handleEdit( scope.$index, scope.row)" size="mini" type="text" >查看任务<i class="el-icon-arrow-right"></i></el-button>
				</template>
			</el-table-column>
      <el-table-column  label="操作" align="center" min-width="150px">
        <template slot-scope="scope" >
          <el-button  type="text"  icon="el-icon-remove-outline" @click="pauseJob(scope.row)" v-if="scope.row.scheduler_status==='运行中'">暂停</el-button>
          <el-button  type="text"  icon="el-icon-circle-check" @click="resumeJob(scope.row)" v-if="scope.row.scheduler_status==='已暂停'">启动</el-button>
          <el-button  type="text"   icon="el-icon-delete" @click="delJob(scope.row)" v-if="scope.row.scheduler_status==='已暂停'">删除</el-button>
          <el-button  type="text"  icon="el-icon-delete"  @click="delJob(scope.row)" v-if="scope.row.scheduler_status==='运行中'">删除</el-button>
        </template>
      </el-table-column>
     
    </el-table>
    <div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="recordsForm.current_page"
        :limit.sync="recordsForm.page_size"
        @pagination="getSmsRecord"
      />
    </div>
  </div>
</template>

<script>
import {errorAlert,successAlert}from "../../../utils/alert"
import { formatTime } from "@/utils/formDate.js"
export default {
  data() {
    return {
   pictLoading:false,
      job_id:'',
      records: [],
       total: 0,
      recordsForm:{
        page_size: 10,
        current_page: 1,
        scheduler_job_id:'',
        scheduler_type:'',
        scheduler_status:'',
        sms_theme:''
      },
      advanced:false,
    };
  },

  computed: {
  },

   mounted() {
    this.$nextTick(function() {
      this.toggleAdvanced();
    });
  },

  created() {
    this.getSmsRecord();
  },

  methods: {
    toggleAdvanced(){
       this.advanced = !this.advanced;
       var foldBoxHeight= document.getElementById("foldBox");
       if (this.advanced == true) {
        foldBoxHeight.style.height = "auto";
        document.getElementById("foldBox").style.display='none';
      } else {
        document.getElementById("foldBox").style.display='inline-block';
      }
     },
     
    handleCopyLink(row) {
      let _this = this;
      this.$copyText(row.sql_text).then(function (e) {
        _this.$message({
          showClose: true,
          message: "复制成功",
          type: "success"
        });
      }, function (e) {
        _this.$message({
          showClose: true,
          message: "复制失败，请手动复制",
          type: "error"
        });
      })
    },
     
    handleEdit(index,row){
       this.$router.push({name:'debriefingTask', path: 'd555',query:{scheduler_job_id:row.scheduler_job_id}});
    },
     
    delJob(row){
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'danger'
      }).then(()=>{
          this.delete(row)
      row.scheduler_status="已删除"
      }).catch(()=>{
        this.$message({
        type: 'info',
        message: '已取消删除'
      }); 
    })
    }, 
      
    async delete(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/delete",{job_id:data.scheduler_job_id} )
      if(res.data.code ==0){
        successAlert(res.data.msg)
         this.getSmsRecord();
      }else{
        errorAlert(res.data.msg)
      }
    },
      
    pauseJob(row){
      this.$confirm('确定暂停该任务吗?', '提示', {
        confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(()=>{
        this.stop(row,)
    }).catch(()=>{
      this.$message({
      type: 'info',
      message: '已取消暂停'
    }); 
    })
    },
   
    async stop(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/stop",{job_id:data.scheduler_job_id} )
      if(res.data.code ==0){
        successAlert(res.data.msg)
         this.getSmsRecord();
      }else{
        errorAlert(res.data.msg)
      }
    },
    
    resumeJob(row){
      let time = formatTime().timers
      let execution=row.next_run_time
      let times=time.replace(/-/g,"/");
      let executions=execution.replace(/-/g,"/");
      if(Date.parse(times)>Date.parse(executions)){
        return this.$confirm('由于您创建的定时任务超时，该任务已失效，请手动删除','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(()=>{})
            }else{
              this.$confirm('确定恢复该任务吗?', '提示', {
            confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{ 
              this.start(row)
            }) .catch(()=>{
              this.$message({
            type: 'info',
            message: '已取消启动'
          }); 
          }) 
          }
    },
    
    async start(data){
      const res =  await this.$http.post("/periodic_task/scheduler_job/restore",{job_id:data.scheduler_job_id} )
      if(res.data.code ==0){
        successAlert(res.data.msg)
        this.getSmsRecord();
      }else{
        errorAlert(res.data.msg)
      }
    },

    headClass() {
      return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },

    change(e){
      this.$forceUpdate()
    },

  
    async getSmsRecord() {
      this.pictLoading = true
      let params = {
        ...this.recordsForm
      };
      const res = await this.$http.get("/periodic_task/scheduler_task_records/get", params)
      this.pictLoading = false
        this.records = res.data.data.records;
      this.total = res.data.data.total
      if (!res.data.code==0) {
        errorAlert(res.data.msg)
      }
    },

    submitSearch(){
       this.recordsForm.current_page=1
      this.getSmsRecord();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.submitSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-link.el-link--info {
    color: #606266;
    font-weight: 350;
    font-size: 12px;
}
.recordsForm{
  margin-left: 30px;
  margin-bottom: 10px;
  }
.el-form-item{
    margin-top: 20px;
}

 /deep/.el-form-item--small .el-form-item__content {
    margin-right: 30px;
}
.el-form-item--small.el-form-item {
    margin-bottom: 3px;
}
.advanced{
  color: #409EFF;
}
#foldBox{
  overflow: hidden;
  height: 100px;
}
/deep/.el-table .cell {
  white-space: pre !important;
}
.el-link.el-link--info {
  color: #000;
  font-weight: 350;
  font-size: 12px;
}
</style>